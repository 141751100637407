<template>
  <div class="CancelSubscription">
    <div dpadding>
      <Spacer num="1" />
      <UserAvatar :user="subscription.creator" size="120px" mauto />
      <Spacer num="1" />
      <div tcenter>
        <div v-size="`120%`">{{ $locale["cancel_subscription_confirm"] }}</div>
        <Spacer num=".5" />
        <p strong>@{{ creator.user }}</p>
      </div>
      <Spacer num="2" />
      <Confirm :confirm="confirm" @cancel="modal && modal.close()" @accept="deleteUser" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["subscription", "modal", "onDelete"],
  data: function() {
    return {
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    deleteUser: async function() {
      this.isLoading(true);
      await Go.sleep(250);

      try {
        this.body = { userId: this.creator._id, userName: this.creator.user, subscriptionId: this.subscription._id };
        this.req = await this.$api.post(`${this.$apiHost}/user/cancelSubscription`, this.body, this.axios);
        this.deleteUserSuccess(this.req.data);
      } catch (error) {
        this.deleteUserError(Go.getErrorMessage(error));
      }

      this.isLoading(false);
    },
    deleteUserError: function(data = {}) {
      this.showMessage(data);
    },
    deleteUserSuccess: function(data = {}) {
      if (!data.success) {
        return this.showMessage(data);
      }

      this.modal.close(() => {
        if (data.message) {
          this.showMessage(data);
        }
      });
    },
  },
  computed: {
    creator: function() {
      return this.subscription.creator;
    },
    confirm: function() {
      return {
        cancel: {
          label: this.$locale["cancel"],
        },
        accept: {
          label: this.$locale["cancel_subscription"],
        },
      };
    },
  },
};
</script>
